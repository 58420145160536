import { useEffect, useState } from 'react';
function ApiMinecraft() {
    const [data, setData] = useState(null);  // Przechowujemy całe dane zwrócone przez API
    const [loading, setLoading] = useState(true);  // Stan ładowania
    const [error, setError] = useState(null);  // Stan błędów
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://api.mcsrvstat.us/3/107.161.154.3:25565`);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();  // Pobieramy dane w formacie JSON
          setData(result);  // Ustawiamy dane w stanie
          console.log(result);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    if (loading) {
      return <div>Pobieranie informacji...</div>;
    }
  
    if (error) {
      return <div>Wystąpił błąd: {error}</div>;
    }
    /*
    // Sprawdzamy, czy serwer jest aktywny
    if (!data.active) {
      return <div>Serwer aktualnie jest offline.</div>;
    }
    //console.log(Object(data));

    const formatDate = (dateString) => {
      const options = {
        year: "numeric", 
        month: "long", 
        day: "numeric", 
        weekday: 'long', 
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return new Date(dateString).toLocaleDateString(undefined, options)
    }*/
    if(!data.online) {
      return <div>Serwer aktualnie jest offline.</div>;
    }
    return (
      <div className='mc-api'>
        <div className='server-details-head'>
          <h2>Minecraft</h2>
        </div>
        <ul className='mc-server-details'>
          <li>{data.ip}:{data.port}</li>
          <li dangerouslySetInnerHTML={{__html: data.motd.html}}></li>
          <li>{data.software} {data.protocol.name}</li>
          <li>Graczy: {data.players.online} / {data.players.max}</li>
        </ul>
      </div>
    );
}
export default ApiMinecraft;