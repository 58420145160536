import './AppFooter.css';
const AppFooter = () =>{
    var currentdata = new Date();
    function DoGory(){
        return window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }
    function DoDiscroda(){
        return window.location.href='https://discord.gg/DmNxau4bnB';
    }
    return (
        <>
            <div className='footer-col'>
                <div className='text-pd-center'>
                    <h3 className='text-center'>Copryright &copy; {currentdata.getFullYear()} and created with &#128150; by <strong>MrKacu13</strong></h3>
                    <div className='btn-group'>
                        <button className='text-center btn' onClick={DoDiscroda} title='Dołącz do naszego serwera Discord'>Discord</button>
                    </div>
                </div>
            </div>
            <div className='footer-auth'>
                <button className='text-center btn' onClick={DoGory}>&#8679; powrót do góry &#8679;</button>
            </div>
        </>
    )
}
export default AppFooter;